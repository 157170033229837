import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import Hamburger from "./Hamburger.js";
import {useState} from 'react';
import logo from './cond-klima-logo.png';
import {HashLink} from "react-router-hash-link";

const Navbar = () => {

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  }

  return (< div > <nav className="navbar navigation container-md">
    <a href="/">
      <img className="logo" src={logo} alt="Cond Klima s.r.o. logo"/>
      < /a>

      <div className="hamburger" onClick={toggleHamburger}>
        <Hamburger isOpen={hamburgerOpen}/>
      </div>

      <div className="navbar-inner">
        <ul className="navbar-content">
          <li className="nav-item" onClick={toggleHamburger}>
            <HashLink smooth="smooth" to="/">
              Úvod
            </HashLink>
            </li >
            <li className="nav-item" onClick={toggleHamburger}>
              <HashLink smooth="smooth" to="/sluzby">
                Služby
              </HashLink>
              < /li >
              <li className="nav-item" onClick={toggleHamburger}>
                <HashLink smooth="smooth" to="/reference">
                  Reference
                </HashLink>
                < /li >
                <li className="nav-item" onClick={toggleHamburger}>
                  <HashLink smooth="smooth" to="/kontakt">
                    Kontakt
                  </HashLink>
                </li>
              </ul>
            </div>
            </nav >

            <style jsx="true">
              {`

            .navigation li a{
              list-style-type: none;
            }

            .navigation ul li {
                overflow: hidden;
                padding: 0;
                margin: 0;
                width: fit-content;
                justify-content: flex-end;
                flex-direction: row;
                display: flex;
            }

            .navigation ul {
                /* padding-top: 20px; */
                gap: 30px;
                flex-direction: row;
                display: flex;
                flex-basis: 70%;
                height: 100%;
                width: 100%;
                padding: 0;
                margin: 0;
                align-items: center;
                justify-content: flex-end;
            }

            .hamburger{
            display: none;
          }

              @media (max-width: 767px){
              .hamburger {
              z-index: 6;
              display: none;
              font-size:1.3rem;
              color:#000000;
              display: flex;
            }
            .App {
              padding-top: 50px;
            }


.navigation .navbar-content{
display: ${ hamburgerOpen ? 'flex': 'none'};
}

.navbar-inner {
gap: 20px;
flex-direction: column;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
text-align: center;
color: black;
}

.navigation ul {
gap: 20px;
flex-direction: column;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
text-align: center;
color: black;
}

.navigation ul li {
gap: 20px;
width: 100%;
justify-content: center;
align-items: center;
flex-direction: column;
float: float;
flex-wrap: wrap;
display: flex;
}

.navigation {
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    height: ${ hamburgerOpen ? '100vh': 'auto'};
    position: fixed;
    background-color: white;
}
nav.navbar.navigation {
    padding: 30px 20px 30px 20px!important;
}
          }
`
              }</style>
            </div >);
            }
            export default Navbar;
