

const Contacts = () => {

  return (<div className="contacts container d-flex flex-column">

    <div className="contact d-flex">

      <div className="contact-text d-flex d-flex flex-column p-4">
        <h1>Projekce</h1>
        <div className="contact-wrap d-flex d-flex flex-column">
          <div className="phone d-flex d-flex flex-row">
            <i className="fa-sharp fa-solid fa-phone"/><a href="tel:+420602147006">+420 602 147 006</a>
          </div>
          <div className="email d-flex d-flex flex-row">
            <i className="fa-solid fa-envelope"/><a href="mailto: info@cond-klima.cz">projekce@cond-klima.cz</a>
          </div>
        </div>
        <div className="address-wrap d-flex d-flex flex-row">
          <div className="address-wrap-left d-flex d-flex flex-column">
            <ul>
              <li>Sportovní 607</li>
              <li>Stará Ves nad Ondřejnicí 739 23</li>
            </ul>
          </div>
          <div className="address-wrap-right">
            <a href="https://goo.gl/maps/fvgMdf7W91BiYvWB6" target="_blank"><button className="btn">Ukázat na mapě</button></a>
          </div>
        </div>
      </div>
      <div className="contact-image d-flex d-flex flex-column d-none d-sm-block d-md-block">
        <div className="image">
          
        </div>
      </div>

    </div>


    <div className="contact contact-reverse d-flex">

      <div className="contact-text d-flex d-flex flex-column p-4">
        <h1>Realizace</h1>
        <div className="contact-wrap d-flex d-flex flex-column">
          <div className="phone d-flex d-flex flex-row">
            <i className="fa-sharp fa-solid fa-phone"/><a href="tel:+420603170643">+420 603 170 643</a>
          </div>
          <div className="email d-flex d-flex flex-row">
            <i className="fa-solid fa-envelope"/><a href="mailto: realizace@cond-klima.cz">realizace@cond-klima.cz</a>
          </div>
        </div>
        <div className="address-wrap d-flex d-flex flex-row">
          <div className="address-wrap-left d-flex d-flex flex-column">
            <ul>
            <li>Sportovní 607</li>
            <li>Stará Ves nad Ondřejnicí 739 23</li>
            </ul>
          </div>
          <div className="address-wrap-right">
            <a href="https://goo.gl/maps/fvgMdf7W91BiYvWB6" target="_blank"><button className="btn">Ukázat na mapě</button></a>
          </div>
        </div>
      </div>

      <div className="contact-text d-flex d-flex flex-column p-4">
        <h1>Servis</h1>
        <div className="contact-wrap d-flex d-flex flex-column">
          <div className="phone d-flex d-flex flex-row">
            <i className="fa-sharp fa-solid fa-phone"/><a href="tel:+420734620742">+420 734 620 742</a>
          </div>
          <div className="email d-flex d-flex flex-row">
            <i className="fa-solid fa-envelope"/><a href="mailto: info@cond-klima.cz">servis@cond-klima.cz</a>
          </div>
        </div>
        <div className="address-wrap d-flex d-flex flex-row">
          <div className="address-wrap-left d-flex d-flex flex-column">
            <ul>
            <li>Sportovní 607</li>
            <li>Stará Ves nad Ondřejnicí 739 23</li>
            </ul>
          </div>
          <div className="address-wrap-right">
            <a href="https://goo.gl/maps/fvgMdf7W91BiYvWB6" target="_blank"><button className="btn">Ukázat na mapě</button></a>
          </div>
        </div>
      </div>





    </div>





  </div>);
}

export default Contacts;
