import img from './header-image.webp';
import s_i_1 from './ck_projektovani_small.webp';
import s_i_2 from './ck_realizace_small.webp';
import s_i_3 from './ck_servis_small.webp';
import LazyLoad from 'react-lazyload';


const Services = () => {

  return (<div className="services d-flex flex-column">

    <div className="categories container d-flex flex-row p-5">

        <a href="#projekce" className="w-100">
    <div className="category d-flex flex-column p-5">
      <div className="cover"></div>
      <div className="image z-index3">
        <i className="fa-solid fa-diagram-project"></i>
      </div>
      <div className="title z-index3">Projekce</div>

    </div>
      </a>

<a href="#realizace" className="w-100">
      <div className="category d-flex flex-column p-5">
        <div className="cover"></div>
        <div className="image z-index3">
          <i className="fa-solid fa-wind"></i>
        </div>
        <div className="title z-index3">Realizace</div>
      </div>
</a>

<a href="#servis" className="w-100">
      <div className="category d-flex flex-column p-5">
        <div className="cover"></div>
        <div className="image z-index3">
          <i className="fa-solid fa-screwdriver-wrench"></i>
        </div>
        <div className="title z-index3">Servis</div>
      </div>
</a>
    </div>

    <div className="container services-container d-flex flex-column">


      <div className="service service-reverse d-flex">

        <div className="service-image d-flex flex-column">
          <div className="image">
          <LazyLoad height={200}>
            <img className="w-100" src={s_i_1} alt="Cond-klima servis image projekce klimatizace" width="600" height="400"/>
            </LazyLoad>
          </div>
        </div>

        <div className="service-text d-flex flex-column p-4" id="projekce">
          <div className="wrap p-5 text-left">
            <h1>Projekce</h1>
            <p>Na základě vašich požadavků a vstupních informací zpracujeme projektovou dokumentaci od stupně studie, přes stavební povolení až po realizační dokumentaci a to v oboru VZT a chlazení. Projektovou dokumentaci jsme schopni vypracovat ve 2D, 3D, BIM.
Výsledná projektová dokumentace je autorizovaná a lze ji využít na dotační programy.</p>
          </div>
        </div>
      </div>

      <div className="service d-flex">

        <div className="service-text d-flex flex-column p-4" id="realizace">
          <div className="wrap p-5 text-left">
            <h1>Realizace VZT a klimatizace</h1>
            <p>Provádíme kompletní dodávku a montáž vzduchotechniky a klimatizace. Při realizaci zakázky se snažíme vždy vybírat a doporučovat zařízení s nejlepší možnou účinností, vysokou spolehlivostí a cenovou dostupností. Nabízíme komplexní řešení pro: průmyslové objekty, kanceláře, haly, školy, bazény, wellness, rodinné a bytové domy. Poskytujeme komplexní servis v oblasti teplovzdušného vytápění a větrání nízkoenergetických, pasivních a nulových domů.
</p><p>Kvalita a funkčnost je naším hlavním cílem!
</p>
          </div>

        </div>

        <div className="service-image d-flex flex-column">
          <div className="image">
          <LazyLoad height={200} offset={100}>
            <img className="w-100" src={s_i_2} alt="Cond-klima servis image realizace vzt klimatizace" width="600" height="400"/>
            </LazyLoad>
          </div>
        </div>

      </div>

      <div className="service service-reverse d-flex">

        <div className="service-image d-flex flex-column">
          <div className="image">
          <LazyLoad height={200} offset={100}>
            <img className="w-100" src={s_i_3} alt="Cond-klima servis image servis service revize" width="600" height="400"/>
            </LazyLoad>
          </div>
        </div>

        <div className="service-text d-flex flex-column p-4" id="servis">
          <div className="wrap p-5 text-left">
            <h1>Servis a revize</h1>
            <p>Pro vzduchotechnická a klimatizační zařízení zajišťujeme pravidelný servis dle doporučení jednotlivých výrobců zařízení, vč. výměny filtrů, revize chladírenských zařízení, revize elektrických zařízení.  Servisní práce provádíme dle norem, jednorázově i dlouhodobě. </p>
          </div>
        </div>
      </div>


    </div>

  </div>);
}

export default Services;
