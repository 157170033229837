
import header_image_t from './final_header.webp';
import s_i_1 from './ck_projektovani_small.webp';
import s_i_2 from './ck_realizace_small.webp';
import s_i_3 from './ck_servis_small.webp';

import {  Link } from "react-router-dom";
import praxe from './praxe2.svg';
import kvalita from './kvalita.svg';
import spolehlivost from './spolehlivost.svg';
import simulace from './simulace.svg';
import provoz from './ekon_provoz.svg';
import reklamace from './min_reklamace.svg';

import homola from './homola.svg';
import vitkovice from './vitkovice.svg';
import zezula from './zezula.svg';
import banik from './banik_logo_web.svg';
import okd from './okd.svg';
import ppl from './ppl.jpeg';
import LazyLoad from 'react-lazyload';

const Home = () => {


    return (
        <div className="home">

            <div className="header container-md d-none d-md-flex d-lg-flex">
              <h1 className="feeling">Pro dobrý pocit...</h1>
              <LazyLoad>
              <img src={header_image_t} alt="Cond-klima header logo" width="1300" height="450"/>
              </LazyLoad>        
              </div>

            <div className="main-content">


            <div className="services margin-100" id="services">
              <h2 className="f-w-700 mb-5">ČEMU SE VĚNUJEME ?</h2>
              <div className="container content">

                <div className="s-card">
                  <div className="image w-100">
                  <img src={s_i_1} alt="cond-klima.cz services image projekce" width="400" height="300"/>
                  </div>
                  <div className="title w-100">
                    <h4 className="m-0">PROJEKCE</h4>
                  </div>
                  <div className="text w-100 h-100">
                    <span>Připravíme pro vás návrh, či projektovou dokumentaci pro optimální řešení větrání, chlazení  a vytápění vybraných prostor.</span>
                  </div>
                  <div className="button w-100">
                    <Link to="/sluzby"><button className="btn">Zjistit více</button></Link>
                  </div>
                </div>

                <div className="s-card">
                  <div className="image w-100">
                  <img src={s_i_2} alt="cond-klima.cz services image realizace vzt klimatizace" width="400" height="300"/>
                  </div>
                  <div className="title w-100">
                    <h4 className="m-0">REALIZACE VZT A KLIMATIZACE</h4>
                  </div>
                  <div className="text w-100 h-100">
                    <span>Zajišťujeme dodávku a odbornou montáž zařízení vzduchotechniky a klimatizace.</span>
                  </div>
                  <div className="button w-100">
                    <Link to="/sluzby"><button className="btn">Zjistit více</button></Link>
                  </div>
                </div>

                <div className="s-card">
                  <div className="image w-100">
                  <img src={s_i_3} alt="cond-klima.cz services image servis revize" width="400" height="300"/>
                  </div>
                  <div className="title w-100">
                    <h4 className="m-0">SERVIS A REVIZE</h4>
                  </div>
                  <div className="text w-100 h-100">
                    <span>Postaráme se o záruční i pozáruční servis VZT a klimatizačních zařízení.</span>
                  </div>
                  <div className="button w-100">
                    <Link to="/sluzby"><button className="btn">Zjistit více</button></Link>
                  </div>
                </div>

              </div>
            </div>

              <div className="w-100 h-100" id="about">
                <h1 className="f-w-700">COND KLIMA s.r.o.</h1>
                <span>Firma byla založena roku 1995 jako čistě realizační a časem doplnila své služby o projekci a servis. Firma se zabývá realizací, projekcí a facility managementem v oblasti vzduchotechniky, klimatizace a vytápění.  Naším hlavním cílem je odborné provedení návrhu a kvalitně odvedená realizace. Do naší práce zařazujeme nové poznatky, abychom mohli zákazníkovi nabídnout nejlepší řešení jak po stránce technické, technologické, tak i finanční.</span>
              </div>

              <div className="why-us container margin-100" id="why-us">

                <h2 className="mb-5">PROČ SI VYBRAT PRÁVĚ NÁS ?</h2>


                <div className="tiles w-100 h-100">

                <div className="tile">
                  <div className="image">
                    <img src={praxe} alt="cond-klima tile image"/>
                  </div>
                  <div className="title">
                    <span>Přes 20 let praxe v oboru</span>
                  </div>
                </div>

                  <div className="tile">
                    <div className="image">
                      <img src={kvalita} alt="cond-klima tile image"/>
                    </div>
                    <div className="title">
                      <span>Prvotřídní kvalita</span>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                      <img src={spolehlivost} alt="cond-klima tile image"/>
                    </div>
                    <div className="title">
                      <span>Spolehlivost</span>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                      <img src={simulace} alt="cond-klima tile image"/>
                    </div>
                    <div className="title">
                      <span>Energetické simulace</span>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                      <img src={provoz} alt="cond-klima tile image"/>
                    </div>
                    <div className="title">
                      <span>Ekonomický provoz</span>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                      <img src={reklamace} alt="cond-klima tile image"/>
                    </div>
                    <div className="title">
                      <span>Minimum reklamací</span>
                    </div>
                  </div>


                </div>

              </div>


              <div className="w-100 h-100" id="cert">
                <h2 className="f-w-700"></h2>
                <p></p>
                <p></p>
              </div>

              <div className="customers container margin-100" id="customers">

                <h2 className="mb-5">NAŠI ZÁKAZNÍCI</h2>


                <div className="tiles w-100 h-100">

                  <div className="tile">
                    <div className="image">
                    <LazyLoad>
                      <img src={homola} alt="cond-klima tile image"/>
                      </LazyLoad>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                    <LazyLoad>
                      <img src={ppl} alt="cond-klima tile image"/>
                      </LazyLoad>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                    <LazyLoad>
                      <img src={okd} alt="cond-klima tile image"/>
                      </LazyLoad>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                    <LazyLoad>
                      <img src={zezula} alt="cond-klima tile image"/>
                      </LazyLoad>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                      <img src={vitkovice} alt="cond-klima tile image"/>
                    </div>
                  </div>

                  <div className="tile">
                    <div className="image">
                    <LazyLoad>
                      <img src={banik} alt="cond-klima tile image"/>
                      </LazyLoad>
                    </div>
                  </div>


                </div>

              </div>

            </div>
        </div>
    );
}

export default Home;
