
import * as React from "react";
import { useState, useEffect } from 'react';
import * as ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home.js';
import Contacts from "./Contacts.js";
import References from "./References.js";
import Footer from "./Footer.js";
import Services from "./Services.js";
import Gdpr from "./Gdpr.js";
import Sitemap from "./Sitemap.js";
import Navbar from "./Navbar.js";
import $ from 'jquery';
import './App.css';
import ScrollToTop from "./ScrollToTop.js";


//import React, { useState } from 'react';
import CookieBot from 'react-cookiebot/lib/CookieBot';
import { Button, View, Text } from 'react-native';
const domainGroupId = '20069888-9a1f-4720-8a4e-28fa1fa93086';

function App() {
  const [hasCookieBot, setHasCookieBot] = useState(undefined);

  return (
      <Router>
         <ScrollToTop />
        <div className="root-inner">
          <Navbar/>
          <div className="App">
            <Routes>

              <Route path="/" element={<Home/>}/>
              <Route path="/sluzby" element={<Services/>}/>
              <Route path="/reference" element={<References/>}/>
              <Route path="/kontakt" element={<Contacts/>}/>
              <Route path="/gdpr" element={<Gdpr/>}/>

              <Route path="*" element={<Home/>}/>

            </Routes>

            <Footer/>
          </div>

          <View style={{ flex: 1, alignItems: 'center' }}>
            <CookieBot domainGroupId={domainGroupId} />
          </View>

        </div>
      </Router>

  );

}

export default App;
