import {  Link } from "react-router-dom";

const Footer = () => {


    return (
      <footer>

        <div className="container">

          <div className="footer-inner d-flex">

            <div className="footer-navigation d-flex flex-column flex-1">
              <div className="footer-head">Navigace</div>
              <ul className="nav d-flex d-flex flex-column">
                <li className="nav-item">
                  <Link to="/">Úvod</Link>
                </li>
                <li className="nav-item">
                  <Link to="/sluzby">Služby</Link>
                </li>
                <li className="nav-item">
                  <Link to="/reference">Reference</Link>
                </li>
                <li className="nav-item">
                  <Link to="/kontakt">Kontakt</Link>
                </li>
                <li className="nav-item">
                  <a href="/condclima-gdpr.pdf">GDPR</a>
                </li>
                <li className="nav-item">
                  <a href="/sitemap.xml">Sitemap</a>
                </li>
              </ul>
            </div>

            <div className="footer-contact d-flex flex-column flex-1">
              <div className="footer-head">Kontakt</div>
              <ul className="nav d-flex d-flex flex-column">
                <li className="nav-item"><i className="fa-solid fa-envelope"></i><a href="mailto:info@cond-clima.cz" title="Napište nám">info@cond-klima.cz</a></li>
                <li className="nav-item"><i className="fa-sharp fa-solid fa-phone"></i><a href="tel:+420702189827" title="Zavolejte nám">+420 702 189 827</a></li>
                <li className="nav-item"><i className="fa-solid fa-map-location-dot"></i><a href="https://goo.gl/maps/fvgMdf7W91BiYvWB6" target="_blank">COND KLIMA s.r.o.</a></li>
                <li className="nav-item"><i className="fa-solid fa-map-location-dot invisible"></i><a href="https://goo.gl/maps/fvgMdf7W91BiYvWB6" target="_blank">Sportovní 607</a></li>
                <li className="nav-item"><i className="fa-solid fa-map-location-dot invisible"></i><a href="https://goo.gl/maps/fvgMdf7W91BiYvWB6" target="_blank">Stará Ves nad Ondřejnicí</a></li>
                            <li className="nav-item"><i className="fa-solid fa-map-location-dot invisible"></i><a href="https://goo.gl/maps/fvgMdf7W91BiYvWB6" target="_blank">739 23</a></li>
                <li className="nav-item"><i className="fa-solid fa-clock"></i><span>Po - Pá: 7:00 - 15:00</span></li>
              </ul>
            </div>

          </div>

        </div>

        <div className="copy container flex mt-5">
          <div className="d-flex justify-content-center">
            <div className="">
            <a href="https://www.freepik.com/free-photo/hvac-technician-working-capacitor-part-condensing-unit_11162306.htm#page=2&query=air%20conditioner&position=24&from_view=search&track=sph">Image by master1305</a> on Freepik
 | <a href="https://www.freepik.com/free-photo/repairman-doing-air-conditioner-service_13377212.htm#query=air%20conditioner%20project&position=24&from_view=search&track=ais">Image by senivpetro</a> on Freepik
 | <a href="https://www.freepik.com/free-photo/image-engineering-objects-workplace-top-view-construction-concept-engineering-tools-vintage-tone-retro-filter-effect-soft-focus-selective-focus_1239244.htm#query=projecting&position=8&from_view=search&track=sph">Image by mindandi</a> on Freepik
              <p>© 2023 <a href="http://marekkalabza.cz">Marek Kalabza</a></p>
            </div>
          </div>
        </div>



      </footer>
    );
}

export default Footer;
