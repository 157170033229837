import r1_f1 from './ref1/foto1.webp';
import r1_f2 from './ref1/foto2.webp';
import r1_f7 from './ref1/foto7.webp';
import r1_f4 from './ref1/foto4.webp';
import r1_f5 from './ref1/foto5.webp';
import r1_f6 from './ref1/foto6.webp';

import r2_f1 from './ref2/foto1.webp';
import r2_f2 from './ref2/foto2.webp';
import r2_f3 from './ref2/foto3.webp';
import r2_f4 from './ref2/foto4.webp';
import r2_f5 from './ref2/foto5.webp';
import r2_f6 from './ref2/foto6.webp';

import r3_f1 from './ref3/foto1.webp';
import r3_f2 from './ref3/foto2.webp';
import r3_f3 from './ref3/foto3.webp';
import r3_f4 from './ref3/foto4.webp';
import r3_f5 from './ref3/foto5.webp';

import r4_f1 from './ref4/foto1.webp';
import r4_f2 from './ref4/foto2.webp';
import r4_f3 from './ref4/foto3.webp';
import r4_f4 from './ref4/foto4.webp';
import r4_f5 from './ref4/foto5.webp';

import r5_f1 from './ref5/foto1.webp';
import r5_f2 from './ref5/foto2.webp';
import r5_f3 from './ref5/foto3.webp';
import r5_f4 from './ref5/foto4.webp';
import r5_f5 from './ref5/foto5.webp';

import r6_f1 from './ref6/foto1.webp';
import r6_f2 from './ref6/foto2.webp';
import r6_f3 from './ref6/foto3.webp';
import r6_f4 from './ref6/foto4.webp';
import r6_f5 from './ref6/foto5.webp';

import LazyLoad from 'react-lazyload';

const References = () => {

  return (
    <div className="references d-flex flex-column">

      <h1>Reference</h1>
      <h3><span>2022</span></h3>

      <div className="reference container d-flex flex-row pt-4 pb-4">
        <div className="images d-flex flex-row flex-wrap">
        <LazyLoad height={400}>
<img  src={r1_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
</LazyLoad>
<LazyLoad height={400}>
<img  src={r1_f6} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
</LazyLoad>
<LazyLoad height={400}>
<img  src={r1_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
</LazyLoad>
<LazyLoad height={400}>
<img  src={r1_f7} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
</LazyLoad>
<LazyLoad height={400}>
<img  src={r1_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
</LazyLoad>
<LazyLoad height={400}>
<img  src={r1_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
</LazyLoad>
        </div>

      </div>

      <div className="reference container d-flex flex-row pt-4 pb-4">
        <div className="images d-flex flex-row flex-wrap">
<LazyLoad height={200}>
        <img  src={r2_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r2_f6} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r2_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r2_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r2_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r2_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
        </div>

      </div>

      <h3><span>2021</span></h3>

      <div className="reference container d-flex flex-row pt-4 pb-4">
        <div className="images d-flex flex-row flex-wrap">

<LazyLoad height={200}>
        <img  src={r3_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r3_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r3_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r3_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>

        </div>

      </div>

      <div className="reference container d-flex flex-row pt-4 pb-4">
        <div className="images d-flex flex-row flex-wrap">
    
<LazyLoad height={200}>
        <img  src={r4_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r4_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r4_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r4_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>

        </div>

      </div>

      <h3><span>2020</span></h3>

      <div className="reference container d-flex flex-row pt-4 pb-4">
        <div className="images d-flex flex-row flex-wrap">
      
<LazyLoad height={200}>
        <img  src={r5_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r5_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r5_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r5_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
        </div>

      </div>

      <h3><span>2019</span></h3>

      <div className="reference container d-flex flex-row pt-4 pb-4">
        <div className="images d-flex flex-row flex-wrap">
        
<LazyLoad height={200}>
        <img  src={r6_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r6_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r6_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
<LazyLoad height={200}>
        <img  src={r6_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        </LazyLoad>
        </div>

      </div>

    </div>
  );
}

export default References;

/*
          <ul className="list-group list-group-flush w-100">
            <li className="list-group-item text-left"><i className="fa-solid fa-arrow-right"/><span className="p-2">Param:</span>Cras justo odio</li>
            <li className="list-group-item text-left"><i className="fa-solid fa-arrow-right"/><span className="p-2">Param:</span>Dapibus ac facilisis in</li>
            <li className="list-group-item text-left"><i className="fa-solid fa-arrow-right"/><span className="p-2">Param:</span>Morbi leo risus</li>
            <li className="list-group-item text-left"><i className="fa-solid fa-arrow-right"/><span className="p-2">Param:</span>Porta ac consectetur ac</li>
            <li className="list-group-item text-left"><i className="fa-solid fa-arrow-right"/><span className="p-2">Param:</span>lum at eros</li>
          </ul>

          return (
            <div className="references d-flex flex-column">

              <h1>Reference</h1>
              <h3><span>2022</span></h3>

              <div className="reference container d-flex flex-row pt-4 pb-4">
                <div className="images d-flex flex-row flex-wrap">
        <img  src={r1_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        <img  src={r1_f6} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        <img  src={r1_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        <img  src={r1_f7} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        <img  src={r1_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
        <img  src={r1_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                </div>
                <div className="text d-flex flex-column w-50 p-5 pt-0 text-left align-items-start">
                  <h3 className="w-100">Reference</h3>

                  <div className="content">
                    <p>Projekce a realiazce.</p>
                  </div>
                </div>
              </div>

              <div className="reference container d-flex flex-row pt-4 pb-4">
                <div className="images d-flex flex-row flex-wrap">
                <img  src={r2_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r2_f6} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r2_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r2_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r2_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r2_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                </div>
                <div className="text d-flex flex-column w-50 p-5 pt-0 text-left align-items-start">
                  <h3 className="w-100">Reference</h3>

                  <div className="content">
                    <p>Projekce a realizace.</p>
                  </div>
                </div>
              </div>

              <h3><span>2021</span></h3>

              <div className="reference container d-flex flex-row pt-4 pb-4">
                <div className="images d-flex flex-row flex-wrap">
                <img  src={r3_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r3_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r3_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r3_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                </div>
                <div className="text d-flex flex-column w-50 p-5 pt-0 text-left align-items-start">
                  <h3 className="w-100">Reference</h3>

                  <div className="content">
                    <p>Projekce a realizace.</p>
                  </div>
                </div>
              </div>

              <div className="reference container d-flex flex-row pt-4 pb-4">
                <div className="images d-flex flex-row flex-wrap">
                <img  src={r4_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r4_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r4_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r4_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                </div>
                <div className="text d-flex flex-column w-50 p-5 pt-0 text-left align-items-start">
                  <h3 className="w-100">Reference</h3>

                  <div className="content">
                    <p>Projekce a realizace.</p>
                  </div>
                </div>
              </div>

              <h3><span>2020</span></h3>

              <div className="reference container d-flex flex-row pt-4 pb-4">
                <div className="images d-flex flex-row flex-wrap">
                <img  src={r5_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r5_f3} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r5_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r5_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                </div>
                <div className="text d-flex flex-column w-50 p-5 pt-0 text-left align-items-start">
                  <h3 className="w-100">Reference</h3>

                  <div className="content">
                    <p>Projekce a realizace.</p>
                  </div>
                </div>
              </div>

              <h3><span>2019</span></h3>

              <div className="reference container d-flex flex-row pt-4 pb-4">
                <div className="images d-flex flex-row flex-wrap">
                <img  src={r6_f5} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r6_f1} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r6_f4} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                <img  src={r6_f2} alt="cond-klima reference image klimatizace projekce servis" width="600" height="400"/>
                </div>
                <div className="text d-flex flex-column w-50 p-5 pt-0 text-left align-items-start">
                  <h3 className="w-100">Reference</h3>

                  <div className="content">
                    <p>Projekce a realizace.</p>
                  </div>
                </div>
              </div>

            </div>
          );

          */
